import { LocationIcon } from "../../assets/location.icon";
import { ImageWithFallback } from "../../pages/console";
import { formatMoney } from "../../utils/functions";
import { Counter } from "../counter";
import { ProductsStructure } from "../products-structure";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

export function CatalogProductDetail({
  product,
  handleAddProduct,
  handleInputProductQuantity,
  handleRemoveProduct,
  cartProducts,
  isExportOutlet,
}) {
  const suppliers = product?.suppliers?.slice(1);
  return (
    <div className='category-products-container'>
      <div
        className='offcanvas offcanvas-end tdp_offcanvas tdp_catalog_product'
        tabindex='-1'
        id='offcanvasCatalogProduct'
        aria-labelledby='offcanvasCatalogProductLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvas-CatalogProductLabel'>
            <i
              className='bi bi-x-lg'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='close-catalog-product-drawer'
            ></i>
            <p>{product?.name}</p>
          </h5>
        </div>
        <div className='offcanvas-body'>
          <div className='product-container'>
            <ImageWithFallback
              fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
              src={`${process.env.REACT_APP_PRODUCT_URL}/${product?.variantId}.png`}
              alt={product?.description}
              isBigScreen={true}
            />
            <div className='product-card'>
              {product?.shipsFrom ? (
                <div className='card-export-details'>
                  <p>
                    <LocationIcon />
                    <span>Ships from {product?.shipsFrom}</span>
                  </p>
                  {product?.incoterms ? <p>{product?.incoterms}</p> : null}
                </div>
              ) : null}
              <h2>{product?.name}</h2>
              <h6>{product?.category}</h6>
              <p className='price'>
                {product?.convertedCurrency}
                {formatMoney(product?.convertedPrice)}
                <span> per CS</span>
              </p>
              <div className='production-details'>
                <div>
                  <p>EAN #</p>
                  <p>{product?.subUnit?.upc}</p>
                </div>
                <div>
                  <p>Production Country</p>
                  {product?.shippingCountry ? (
                    <p>
                      {getUnicodeFlagIcon(product?.shippingCountry)}{" "}
                      {product?.shippingCountry}
                    </p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </div>
              <div class='accordion' id='accordionExample'>
                <div class='accordion-item'>
                  <h2 class='accordion-header'>
                    <button
                      class='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Description
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    class='accordion-collapse collapse show'
                    data-bs-parent='#accordionExample'
                  >
                    <div class='accordion-body'>
                      {product?.description || "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className='quantity-container'>
                <h5>Quantity</h5>
                <Counter
                  handleAddProduct={handleAddProduct}
                  handleInputProductQuantity={handleInputProductQuantity}
                  handleRemoveProduct={handleRemoveProduct}
                  cartProducts={cartProducts}
                  product={product}
                />
              </div>
            </div>
          </div>
          {isExportOutlet && (suppliers || []).length ? (
            <div className='other_products tdp_product_catalog'>
              <h5>Ships from other locations</h5>
              <div className='supplier_product_container'>
                {(suppliers || []).map((supplier, key) => (
                  <div className='supplier_product' key={key}>
                    <div className='info-wrapper'>
                      <div className='img_container'>
                        <ImageWithFallback
                          fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product?.variantId}.png`}
                          src={`${process.env.REACT_APP_PRODUCT_URL}/${product?.variantId}.png`}
                          alt={product?.description}
                          isBigScreen={true}
                        />
                      </div>
                      <div className='details'>
                        <h2>
                          {supplier?.currency?.symbol}
                          {formatMoney(
                            supplier?.[product?.incoterms?.toLowerCase()]
                          )}
                        </h2>
                        <h6>{product?.name}</h6>
                        <div className='card-export-details'>
                          <p>
                            <LocationIcon />
                            <span>
                              Ships from {supplier?.state || ""}{" "}
                              {supplier?.country || ""}
                            </span>
                          </p>
                          <p>{product?.incoterms}</p>
                        </div>
                      </div>
                    </div>
                    <div className='cart_add'>
                      {cartProducts.find(
                        (cartProduct) =>
                          cartProduct._id === product._id &&
                          cartProduct?.supplier === supplier?.companyId
                      ) ? (
                        <Counter
                          cartProducts={cartProducts}
                          product={{ ...product, supplier: supplier.companyId }}
                          handleAddProduct={handleAddProduct}
                          handleRemoveProduct={handleRemoveProduct}
                          handleInputProductQuantity={
                            handleInputProductQuantity
                          }
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddProduct(
                              { ...product, supplier: supplier.companyId },
                              supplier.companyId
                            );
                          }}
                        >
                          Add to cart
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className='other_products tdp_product_catalog'>
            <h5>Similar Items</h5>
            <ProductsStructure
              products={product?.similarItems || []}
              handleAddProduct={handleAddProduct}
              cartProducts={cartProducts}
              handleInputProductQuantity={handleInputProductQuantity}
              handleRemoveProduct={handleRemoveProduct}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
