/* eslint-disable no-unreachable */
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorHandler, LSUserData, renderErrorToast } from "../utils/functions";
import {
  BankingInformation,
  DocumentsUpload,
  LegalCompliance,
} from "./register";
import { useFormik } from "formik";
import refreshOutlet from "../utils/refreshOutlet";
import { VerifyIdentity } from "./onboarding-old";
import { COUNTRIES } from "../utils/countries";

export function Onboarding() {
  const navigate = useNavigate();
  const { retailOutlets: [retailOutlet] = [], apiKey } = LSUserData() || {};
  const [stepKey, setStepKey] = useState(1);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { _id, outletBusinessName, company, account, country, kyc } =
    retailOutlet || {};

  useEffect(() => {
    handleFetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      businessStatus: company?.kyb?.businessType ?? "",
      businessRegistrationNumber: company?.kyb?.rcNumber ?? "",
      countryCodeOfIncorporation: country,
      countryOfIncorporation: COUNTRIES.find((el) => el.code === country)?.name,
      businessAccountName: account?.bankName ?? "",
      businessAccountNumber: account?.accountNumber ?? "",
      businessAccountSignatoryBVN: company?.kyb?.bvn ?? "",
    },
    onSubmit: (values) => handleNextSteps(values),
    // validationSchema: registrationSteps === 2 ? RegisterScreenOneSchema : null,
  });

  const openSuccessModal = () => {
    toast(<SuccessModal navigate={navigate} />, {
      autoClose: 3000,
      toastId: "success",
      position: "top-right",
    });
  };

  useEffect(() => {
    let key = 1;
    if (
      company?.kyb &&
      company?.kyb?.businessType &&
      company?.kyb?.rcNumber &&
      country !== "NG"
    ) {
      key = 4;
    }
    if (
      company?.kyb &&
      company?.kyb?.businessType &&
      company?.kyb?.rcNumber &&
      country === "NG"
    ) {
      key = 2;
    }
    if (
      company?.kyb &&
      company?.kyb?.businessType &&
      company?.kyb?.rcNumber &&
      company?.kyb?.bvn &&
      account?.accountNumber &&
      account?.bankName &&
      country === "NG"
    ) {
      key = 3;
    }
    setStepKey(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextSteps = () => {
    let values = {};
    if (stepKey === 1) {
      if (formik.values.countryCodeOfIncorporation !== country)
        return renderErrorToast(
          "Invalid country",
          "Your country of incorporation must be the same as your company address country",
          "country-err-incorporation"
        );
      values = {
        businessStatus: formik.values.businessStatus,
        registrationNumber: formik.values.businessRegistrationNumber,
        countryOfIncorporation: formik.values.countryCodeOfIncorporation,
      };
    }
    if (stepKey === 2) {
      values = {
        companyBankAccountName: formik.values.businessAccountName,
        companyBankAccountNumber: formik.values.businessAccountNumber,
        companyAccountSignatoryBvn: formik.values.businessAccountSignatoryBVN,
      };
      if (company?.kyb?.businessType)
        values = {
          ...values,
          businessStatus: company?.kyb?.businessType,
        };
      if (company?.kyb?.rcNumber)
        values = {
          ...values,
          registrationNumber: company?.kyb?.rcNumber,
        };
    }
    if (stepKey === 3) {
      values = { images: formik.values.images };
    }
    handleUpdateOutlet(values);
  };

  const handleFetchBanks = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V2}/list-banks`,
        { headers: { "x-api-key": apiKey } }
      );
      setBanks(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleNextStep = () => {
    if (country !== "NG") {
      if (kyc?.idVerified) {
        // stripe is done and customer identity is verified
        return navigate("/console");
      }
      return setStepKey(4);
    } else {
      // just keep in increasing step
      if (stepKey < 3 && stepKey + 1) setStepKey(stepKey + 1);
    }
  };

  const handleUpdateOutlet = async (values) => {
    if (!Object.keys(values).length)
      return renderErrorToast("Invalid requests");
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/procurement/key_account/edit`,
        { ...values, _id, outletBusinessName },
        { headers: { "x-api-key": apiKey } }
      );
      refreshOutlet();
      if (stepKey === 1 && country !== "NG" && !kyc?.idVerified)
        return setStepKey(4);
      if (stepKey < 3) {
        renderErrorToast(
          "Successful information update!",
          "Your account details have been updated",
          "acc-update-success-1"
        );
        if (country === "NG" && stepKey + 1 < 4) setStepKey(stepKey + 1);
        else navigate("/console");
      } else {
        openSuccessModal();
        navigate("/console");
      }
    } catch (error) {
      console.log({ error });
      const {
        message = "There was a problem updating your details. Please try again later",
      } = errorHandler(error);
      renderErrorToast(
        "Oops! Something went wrong",
        message,
        "acc-update-failed-1"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='tdp_onboarding'>
      <div className='header'>
        <div className='cancel__'>
          <p onClick={() => navigate("/console")}>
            <i className='bi bi-x-lg'></i>
          </p>
          <h4>Complete Onboarding</h4>
        </div>
        <div className='action-buttons'>
          <button
            onClick={() => {
              if (loading) return;
              refreshOutlet();
              navigate("/console");
            }}
          >
            Finish later
          </button>
          {stepKey > 2 ? null : (
            <button
              className='_continue'
              onClick={() => {
                if (loading) return;
                refreshOutlet();
                handleNextStep();
              }}
            >
              Skip
            </button>
          )}
        </div>
      </div>
      <div className='tdp_register'>
        <div className='body container'>
          <div className='row'>
            <div className='col-4'></div>
            <div className='col-4 form-container'>
              {stepKey === 1 ? (
                <LegalCompliance
                  formik={formik}
                  nextStep={formik.handleSubmit}
                  loading={loading}
                />
              ) : stepKey === 2 ? (
                <BankingInformation
                  formik={formik}
                  nextStep={formik.handleSubmit}
                  loading={loading}
                  banks={banks}
                />
              ) : stepKey === 3 ? (
                <DocumentsUpload
                  formik={formik}
                  nextStep={formik.handleSubmit}
                  loading={loading}
                />
              ) : stepKey === 4 ? (
                <VerifyIdentity />
              ) : null}
            </div>
            <div className='col-4'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SuccessModal({ navigate }) {
  return (
    <>
      <div className='tdp_modal'>
        <>
          <h2>
            <span>Nice!</span> your setup is complete
          </h2>
          <h5>
            You’ve completed your onboarding, you can now continue creating
            orders.
          </h5>
          {/* <div className='action_'>
            <button
              onClick={() => {
                navigate("/console");
                toast.dismiss();
              }}
            >
              Back to Home
            </button>
          </div> */}
        </>
      </div>
    </>
  );
}
