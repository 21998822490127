import { Outlet, useNavigate } from "react-router-dom";
import { AuthedHeader } from "./auth-header";
import { BuildingStore } from "../assets/building-store";
import { Orders } from "../assets/orders";
import { InvoiceSvg } from "../assets/invoice";
import { ShoppingCart } from "../assets/shopping-cart";
import { AccountStatementSvg } from "../assets/account-statement-svg";
import { LogoutSvg } from "../assets/logout";
import { LSUserData, userLoggout } from "../utils/functions";
import { useState } from "react";
import {
  LOCAL_SELECTED_CURRENCY,
  SUPPORTED_CURRENCIES,
} from "../utils/constants";

export function Layout() {
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem(LOCAL_SELECTED_CURRENCY) ||
      SUPPORTED_CURRENCIES[0].title // USD
  );

  return (
    <div
      className='container-fluid tdp_layout'
      onClick={() => setShowCurrencyDropdown(false)}
    >
      <div className='row'>
        <div className='col-2 side-nav-container'>
          <div className='sticky-col-2'>
            <SideBar />
          </div>
        </div>
        <div className='col-10' style={{ background: "#fcfcfc" }}>
          <AuthedHeader
            showCurrencyDropdown={showCurrencyDropdown}
            setShowCurrencyDropdown={setShowCurrencyDropdown}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
          />
          <div className='content-wrapper'>
            <Outlet context={{ selectedCurrency }} />
          </div>
        </div>
      </div>
    </div>
  );
}

function SideBar() {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const { retailOutlets: [retailOutlet] = [] } = LSUserData() || {};

  if (!LSUserData()) return window.location.replace("/login");

  return (
    <>
      <div className='outlet-handler'>
        <span>
          <img
            src='/images/icons/building.svg'
            alt='td-icon-svg'
            className='img-fluid'
          />
        </span>
        <p>{retailOutlet?.outletBusinessName || ""}</p>
      </div>
      <div className='tdp_side_nav'>
        <div
          className={`nav_menu ${pathname === "/console" && "active"}`}
          onClick={() => navigate("/console")}
        >
          <BuildingStore />
          <div>
            <h4>Home</h4>
            <h6>Console overview</h6>
          </div>
        </div>
        <div
          className={`nav_menu ${pathname === "/console/orders" && "active"}`}
          onClick={() => navigate("/console/orders")}
        >
          <Orders />
          <div>
            <h4>Orders</h4>
            <h6>Create and track orders</h6>
          </div>
        </div>
        <div
          className={`nav_menu ${pathname === "/console/invoices" && "active"}`}
          onClick={() => navigate("/console/invoices")}
        >
          <InvoiceSvg />
          <div>
            <h4>Invoices</h4>
            <h6>Review and pay invoices</h6>
          </div>
        </div>
        <div
          className={`nav_menu ${pathname === "/console/catalog" && "active"}`}
          onClick={() => navigate("/console/catalog")}
        >
          <ShoppingCart />
          <div>
            <h4>Catalog</h4>
            <h6>Browse available products</h6>
          </div>
        </div>
        {/*
      <div
        className={`nav_menu ${pathname === "/console/suppliers" && "active"}`}
        onClick={() => navigate("/console/suppliers")}
      >
        <Cabinet />
        <div>
          <h4>Suppliers</h4>
          <h6>Manage your suppliers</h6>
        </div>
      </div>
      <div
        className={`nav_menu ${pathname === "/console/reports" && "active"}`}
        onClick={() => navigate("/console/reports")}
      >
        <ReportSvg />
        <div>
          <h4>Reports</h4>
          <h6>Download reports</h6>
        </div>
      </div> */}
        <div
          className={`nav_menu ${
            pathname === "/console/account-statement" && "active"
          }`}
          onClick={() => navigate("/console/account-statement")}
        >
          <AccountStatementSvg />
          <div>
            <h4>Account Statement</h4>
            <h6>Track account activities</h6>
          </div>
        </div>
        <div className={`nav_menu logout`} onClick={userLoggout}>
          <LogoutSvg />
          <div>
            <h4>Logout</h4>
          </div>
        </div>
      </div>
    </>
  );
}
