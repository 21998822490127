import { useEffect, useState } from "react";
import { AuthWrapper } from "../components/auth-wrapper";
import useTimer from "../hooks/useTimer";
import axios from "axios";
import { errorHandler, renderErrorToast } from "../utils/functions";
import { CustomToast } from "../components/toast-component";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isAnExportOutlet,
  SHOP,
  SHOPTOPUP_DOMAIN,
  TD_DOMAIN,
} from "../utils/constants";

const OTPContainer = ({ values, userIsOnLoginScreen: isLoginScreen }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [resendingToken, setIsResendingToken] = useState(false);
  const [seconds, setStartCountdown] = useTimer();
  const [loading, setLoading] = useState(false);

  const sendUserEmailOTP = async (props) => {
    if (props?.isResending) setIsResendingToken(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        {
          mode: "email",
          email: values.contactEmail.toLowerCase(),
          domain: TD_DOMAIN,
        }
      );
      setLoading(false);
      setIsResendingToken(false);
      setStartCountdown(true);
      setToken("");
      renderErrorToast(
        "Verification Token Sent!",
        `Please check your email (${values.contactEmail}) for a verification token`,
        "sent-token-success"
      );
    } catch (error) {
      console.log("error sending user email otp");
      const { message } = errorHandler(error);
      toast(<CustomToast title={"Error sending token"} bodyText={message} />, {
        toastId: "err-1",
        position: "bottom-right",
      });
    }
  };

  const verifyUserToken = async () => {
    if (!values.contactEmail)
      return renderErrorToast(
        "Invalid Email address",
        "Please enter a valid email address",
        "invalid-email-addresss-2"
      );
    try {
      setLoading(true);
      setStartCountdown(false);
      const {
        data: { res },
      } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/verify-totp`,
        { email: values.contactEmail.toLowerCase(), token }
      );
      if (values.action === "register")
        return handleRegisterUser({
          ...values,
          loginAccessToken: res.accessToken,
        });
      if (values.action === "login") return handleLoginUser(res.accessToken);
    } catch (error) {
      console.log("sending user otp", error);
      setStartCountdown(true);
      const { message } = errorHandler(error);
      setLoading(false);
      toast(
        <CustomToast title={"Error verifying token"} bodyText={message} />,
        {
          toastId: "err-verifying-token",
          position: "bottom-right",
        }
      );
    }
  };

  useEffect(() => {
    if (!values.contactEmail)
      return renderErrorToast(
        "Invalid Email address",
        "Please enter a valid email address",
        "invalid-email-addresss-2"
      );
    sendUserEmailOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegisterUser = async (values) => {
    try {
      const data = {
        outletBusinessName: values.outletBusinessName,
        contactRole: values.contactPosition,
        contactName: `${values.firstName} ${values.lastName}`,
        country: values.countryCode,
        email: values.contactEmail.toLowerCase(),
        gender: null,
        domain: values.countryCode === "GB" ? TD_DOMAIN : SHOPTOPUP_DOMAIN,
        streetName: values?.storeAddress || "",
        lga: values?.addressData?.ProvinceName || "",
        state: values?.state || values?.countryCode,
        latitude: values?.latitude || "2.320",
        longitude: values?.longitude || "4.456",
        outletTypeId: values?.outletTypeId || "",
        extChannel: SHOP.WEB,
        contactPhone: values?.formattedPhoneNumber
          ? `${values.formattedPhoneNumber}`
          : "",
        phone: values?.formattedPhoneNumber
          ? `${values.formattedPhoneNumber}`
          : "",
        businessStatus: values?.businessStatus || "",
        registrationNumber: values?.businessRegistrationNumber || "",
        taxId: null,
        contactEmail: values.contactEmail.toLowerCase(),
        companyBankAccountName: values?.businessAccountName || "",
        companyBankAccountNumber: values?.businessAccountNumber || "",
        companyAccountSignatoryBvn: values?.businessAccountSignatoryBVN || "",
        images: values?.images || [],
        postCode: values?.postcode || "",
      };
      await axios.post(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/procurement/key_account/create`,
        data
      );
      handleLoginUser(values.loginAccessToken);
    } catch (error) {
      console.log("error registering ", error.response, error.data);
      setLoading(false);
      const { message = "Failed to register user" } = errorHandler(error);
      renderErrorToast(
        "Oops! Something went wrong",
        message.length
          ? message
          : "There was an issue creating your user. Please try again or reach out to support",
        "error-create-2"
      );
    }
  };

  const handleLoginUser = async (accessToken) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v4/loginWithToken`,
        { accessToken, email: values.contactEmail.toLowerCase() }
      );
      const isExportOutlet = isAnExportOutlet(data?.retailOutlets?.[0]);
      localStorage.setItem("IS_EXPORT_OUTLET", isExportOutlet);
      localStorage.setItem("userData", JSON.stringify(data));
      return navigate("/console");
    } catch (err) {
      setLoading(false);
      const { message } = errorHandler(err);
      renderErrorToast(
        "Ooops! Something went wrong",
        message ??
          "There was an issue creating your user. Please try again or reach out to support",
        "error-login-err"
      );
    }
  };

  return (
    <div className='form-section'>
      <div className='form-itself'>
        <h3>Confirm signup</h3>
        <h5>Enter the verification code we sent to your email address</h5>
        <div className='v-input__'>
          <input
            placeholder='Enter verification code'
            className='form-control'
            value={token}
            disabled={resendingToken || !values.contactEmail}
            maxLength={6}
            onChange={({ target: { value } }) => setToken(value)}
          />
          <span
            onClick={() => {
              if (seconds) return;
              setToken("");
              return sendUserEmailOTP({ isResending: true });
            }}
          >
            {resendingToken ? (
              <div
                class='spinner-border text-success app-spinner input-spinner'
                role='status'
              >
                <span class='sr-only'></span>
              </div>
            ) : !seconds ? (
              "Resend"
            ) : (
              `Resend in ${seconds}s`
            )}
          </span>
        </div>
        <button
          disabled={token.length < 6 || loading}
          onClick={verifyUserToken}
        >
          {loading ? (
            <div class='spinner-border text-success app-spinner' role='status'>
              <span class='sr-only'></span>
            </div>
          ) : (
            "Continue"
          )}
        </button>
        <h6>
          Still haven’t received a code? Check your spam folder, or{" "}
          <span
            onClick={() => {
              setToken("");
              if (isLoginScreen) return navigate("/login", { state: values });
              navigate("/register", { state: { ...values } });
            }}
          >
            try a different email.
          </span>
        </h6>
      </div>
    </div>
  );
};

export const ConfirmOTP = () => {
  const { state } = useLocation();
  console.log({ state });
  return (
    <div className='tdp_register'>
      <div className='form-container'>
        <AuthWrapper
          children={
            <OTPContainer
              values={{ ...state }}
              userIsOnLoginScreen={state.action !== "register"}
            />
          }
          userIsOnLoginScreen={state.action !== "register"}
        />
      </div>
    </div>
  );
};
