import { useEffect, useRef, useState } from "react";

export function CustomSelect({
  placeholder = "Select",
  hasInputIcon = false,
  action,
  items = [],
  emptyText = "No Items Found",
  valueToDisplay = "",
  disabled = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown when clicking outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='custom-select-wrapper' ref={dropdownRef}>
      <div className='custom-select custom-input'>
        <div
          className='input_wrapper'
          onClick={() => {
            if (disabled) return;
            // setOpen();
            setIsOpen(!isOpen);
          }}
        >
          <input
            className='form-control'
            placeholder={placeholder}
            value={valueToDisplay}
            disabled={disabled}
          />
          <i className='bi bi-chevron-down'></i>
        </div>
        {isOpen ? (
          <div className='_dropdown'>
            {!items.length ? (
              <li>{emptyText}</li>
            ) : (
              items.map((el, key) => (
                <li
                  key={key}
                  onClick={() => {
                    action(el);
                    setIsOpen(false);
                  }}
                >
                  <div className=''>
                    <p>{el.title}</p>
                    <p>{el.subtext}</p>
                  </div>
                </li>
              ))
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
