import { ImageWithFallback } from "../pages/console";
import { formatMoney } from "../utils/functions";
import { ProductEmptyList } from "./drawers/products.drawer";

export function ProductsStructure({
  products = [],
  handleAddProduct,
  handleInputProductQuantity,
  handleRemoveProduct,
  cartProducts = [],
  searchText = "",
  selectedCategory = null,
}) {
  return !products.length ? (
    <ProductEmptyList
      searchText={searchText}
      selectedCategory={selectedCategory}
    />
  ) : (
    <div className='product-listing'>
      {products.map((el, key) => (
        <div className='_product' key={key}>
          <ImageWithFallback
            src={`https://pimg.tradedepot.co/xs/${el.variantId}.png`}
            fallback={`https://td-dev-img.s3.amazonaws.com/xs/${el.variantId}.png`}
            alt={el.name}
          />
          <div className=''>
            <h3>
              {el?.currency?.symbol}
              {formatMoney(el?.price)}
            </h3>
            <h6>{el.name}</h6>
            {cartProducts.find((product) => product._id === el._id) ? (
              <div className='plus_minus_controller' key={el._id}>
                <span onClick={() => handleRemoveProduct(el, el.supplier)}>
                  <i className='bi bi-dash-lg'></i>
                </span>
                <input
                  value={
                    cartProducts.find((product) => product._id === el._id).count
                  }
                  onChange={({ target: { value } }) =>
                    handleInputProductQuantity(el._id, value)
                  }
                />
                <span onClick={() => handleAddProduct(el, el.supplier)}>
                  <i className='bi bi-plus-lg'></i>
                </span>
              </div>
            ) : (
              <button onClick={() => handleAddProduct(el, el.supplier)}>
                Add
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
